
export default {
    props: {
        image: {
            type: [String, Object],
            required: true,
        },
        prefix: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        /* Default is complete image with text overlay, dense is a slim white card with small image*/
        dense: {
            type: Boolean,
            default: false,
        },
    },
}
